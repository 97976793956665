import Service from './Service'

export default class PostsService extends Service {
  createPost = async (values) => {
    return await this._post('/posts', values)
  }

  updatePost = async (uuid, values) => {
    return await this._put(`/posts/${uuid}`, values)
  }

  findAllPostsByPage = async (page = 0) => {
    return await this._get(
      `/posts?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&Type~not=article`
    )
  }

  findAllPostsByPageV2 = async (page = 0) => {
    const data = await this._get(
      `/posts/feed?order=-Score,-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&Type~not=article`
    )
    return data
  }

  updateClickPostsTypeAd = async (UUID) => {
    return await this._post(`/posts/${UUID}/click`)
  }

  updateApprovalStatus = async (UUID, values) => {
    return await this._put(`/posts/${UUID}/approval-status`, values)
  }

  findAllPostByAuthorUUIDAndPageWithVideo = async (authorUUID, page) => {
    return await this._get(
      `/posts?order=-CreatedAt&AuthorUUID=${authorUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&Metadata.Type=youtube&Metadata.Type=vimeo&Metadata.Type=video&Type=default&offset=${
        page * 5
      }&limit=5`
    )
  }

  findAllProductPostByAuthorUUIDAndPage = async (authorUUID, page) => {
    return await this._get(
      `/posts?order=-CreatedAt&AuthorUUID=${authorUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&Type=product&offset=${
        page * 5
      }&limit=5`
    )
  }

  findPostsCountByAuthorUUID = async (AuthorUUID) => {
    return await this._get(`/posts?limit=0&offset=0&AuthorUUID=${AuthorUUID}&Type~not=article`)
  }

  findAllPostsByAuthorUUIDAndPage = async (AuthorUUID, page) => {
    return await this._get(
      `/posts?order=-CreatedAt&AuthorUUID=${AuthorUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&Type~not=article`
    )
  }

  findCountProductPostsByAuthorUUID = async (authorUUID) => {
    const page = await this._get(`/posts?AuthorUUID=${authorUUID}&Type=product&offset=0&limit=0`)
    return page.Count
  }

  findAllFeaturedPosts = async () => {
    return await this._get(
      '/posts?order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&IsFeatured=true&Type~not=article&offset=0&limit=10'
    )
  }

  findPostByUUID = async (uuid) => {
    return await this._get(
      `/posts/${uuid}?order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes,Group`
    )
  }

  findCommentByUUID = async (uuid) => {
    return await this._get(`/posts/comments/${uuid}?include=Author,OwnLike`)
  }

  findPostByTagNameAndPage = async (tagName, page = 0) => {
    return await this._get(
      `/posts/by-tag/${tagName}?order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5&Type~not=article`
    )
  }

  findPostProductsByTagNameAndPage = async (tagName, page = 0) => {
    return await this._get(
      `/posts/by-tag/${tagName}?order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&Type=product&offset=${
        page * 5
      }&limit=5`
    )
  }

  findPostByInteraction = async (authorUUID, page = 0) => {
    return await this._get(
      `/posts/by-interaction?order=-CreatedAt&AuthorUUID=${authorUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5`
    )
  }

  findPostByMention = async (authorUUID, page = 0) => {
    return await this._get(
      `/posts/by-mention?order=-CreatedAt&AuthorUUID=${authorUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&offset=${
        page * 5
      }&limit=5`
    )
  }

  deletePostByUUID = async (uuid) => {
    return await this._delete(`/posts/${uuid}`)
  }

  deleteCommentByUUID = async (uuid) => {
    return await this._delete(`/posts/comments/${uuid}`)
  }

  toggleLikeByPostUUIDAndUserUUID = async (postUUID, userUUID) => {
    return await this._post('/posts/likes/toggle', { PostUUID: postUUID, UserUUID: userUUID })
  }

  toggleCommentLikeByCommentUUIDAndAuthorUUID = async (commentUUID, authorUUID) => {
    return await this._post('/posts/comments/likes/toggle', { CommentUUID: commentUUID, AuthorUUID: authorUUID })
  }

  createCommentByPostUUIDAndAuthorUUIDAndContentAndMentionsAndHashtags = async (
    postUUID,
    AuthorUUID,
    content,
    mentions,
    hashtags
  ) => {
    return await this._post('/posts/comments', {
      PostUUID: postUUID,
      AuthorUUID: AuthorUUID,
      Content: content,
      Mentions: mentions,
      Hashtags: hashtags,
    })
  }

  findAllCommentsByPostUUID = async (postUUID) => {
    return await this._get(`/posts/comments?PostUUID=${postUUID}&include=Author,OwnLike&order=CreatedAt`)
  }

  updatePostMetaByUUID = async (uuid, values) => {
    return await this._put(`/posts/${uuid}/meta`, values)
  }

  /*REVISAR SI FUNCIONA BIEN*/
  searchPostsByQuery = async (query) => {
    return await this._get(`/posts?Content~search=${query}&Type=default&offset=0&limit=10&include=Author`)
  }

  searchProductPostsByQuery = async (query) => {
    return await this._get(`/posts?Content~search=${query}&Type=product&offset=0&limit=10&include=Author`)
  }

  reportPostByPostUUIDAndReason = async (postUUID, reason) => {
    return await this._post(`/posts/${postUUID}/report`, { Reason: reason })
  }

  reportCommentByCommentUUIDAndReason = async (commentUUID, reason) => {
    return await this._post(`/posts/comments/${commentUUID}/report`, { Reason: reason })
  }

  findAllPostByAuthorUUIDWithFile = async (authorUUID) => {
    return await this._get(
      `/posts?order=-CreatedAt&AuthorUUID=${authorUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes&Metadata.Type=file`
    )
  }

  subscribeToPostByPostUUIDAndAuthorUUID = async (postUUID, authorUUID) => {
    return await this._post(`/posts/${postUUID}/subscribe`, { AuthorUUID: authorUUID })
  }

  unsubscribeToPostByPostUUIDAndAuthorUUID = async (postUUID, authorUUID) => {
    return await this._post(`/posts/${postUUID}/unsubscribe`, { AuthorUUID: authorUUID })
  }

  findLikesByPostUUIDAndPage = async (postUUID, page = 0) => {
    return await this._get(`/posts/likes?PostUUID=${postUUID}&include=Author&offset=${page * 20}&limit=20`)
  }

  findTrendingHashtags = async () => {
    return await this._get('/posts/trending/hashtags')
  }

  readPostByPostUUIDAndAuthorUUIDAndFeedUUID = async (postUUID, authorUUID, feedUUID) => {
    return await this._post(`/posts/${postUUID}/read`, { AuthorUUID: authorUUID, FeedUUID: feedUUID })
  }

  findAllSegments = async () => {
    return await this._get('/segments')
  }

  /* Articles */
  findAllArticlesByPage = async (page = 0) => {
    return await this._get(
      `/posts?order=-CreatedAt&include=Category&offset=${page * 5}&limit=5&Type=article&Extra.IsPublish=true`
    )
  }

  findLastEightArticles = async () => {
    return await this._get(
      `/posts?order=-CreatedAt&include=Category&offset=0&limit=8&Type=article&Extra.IsPublish=true`
    )
  }

  findArticleByUUID = async (uuid) => {
    return await this._get(
      `/posts/${uuid}?order=Extra.PublishedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes,Category&Type=article&Extra.IsPublish=true`
    )
  }

  findPostAdByFeedUUID = (feedUUID) => {
    return this._get(
      `/posts/ad?FeedUUID=${feedUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,LatestLikes`
    )
  }

  getStrenghts = async () => {
    return this._get('/users/strengths')
  }
}
